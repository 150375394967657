import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CallServiceAbstract } from "../call.service";
import { SettingsService } from "../../settings.service";
import { AuthenticationService } from "../../auth/Authentication/authentication.service";
import { Subject } from 'rxjs';
import { NotificationService } from '../../notification.service';
import { StateService } from '../../state/state.service';

@Injectable({
    providedIn: 'root'
})

export class GeneralEnterpriseCompanies extends CallServiceAbstract {
    constructor(
        http: HttpClient,
        settings: SettingsService,
        authentication: AuthenticationService,
        notify: NotificationService,
        state: StateService,
    ) {
        super(http, settings, authentication, notify, state, 'General_EnterpriseCompanies', 'Собствена фирма');
    }

    public predefineState(state) {
        state = super.predefineState(state);
        state.expand = [
            'Company($select=Name)',
        ];
        state.select = [
            'Id'
        ];
        state.take = 9999999;
        return state;
    }

}