import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/services/auth/Authentication/authentication.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  public user: string = '';
  public password: string = '';
  public hasError: boolean = false;
  public loading: boolean = false;

  constructor(
    private auth: AuthenticationService,
    private notify: NotificationService,
    private _router: Router,
  ) {
    this.notify.getLoading$()
      .subscribe((state) => this.loading = state)
  }

  login() {
    this.hasError = false;
    this.notify.setLoading(true);
    this.auth.login(this.user, this.password)
      .then(
        data => {
          this.notify.setLoading(false);
          if (!data.hasOwnProperty('error')) {
            this._router.navigate(['']);
          }
          else {
            // this.showAlert('alertSignin');
            // this._alertService.error(data.error.body.error);
          }
        })
      .catch(err => {
        this.notify.setLoading(false);
        this.hasError = true;
        // this.showAlert('alertSignin');
        // this._alertService.error(error);
        this.notify.notify({
          type: 'error',
          title: 'Невалидно потребителско име',
        });
      });
  }

}
