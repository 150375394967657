import { Subject, Observable, interval } from "rxjs";
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { LogService } from "../log/log.service";

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private stateObservable: any;
  private currentMessage = {
    message: null,
    emmited: null
  };
  private intervalObz: any;

  constructor(private l: LogService) {
    this.stateObservable = new Subject();
    this.intervalObz = interval(100);

    this.intervalObz.subscribe(
      () => {
        if (this.currentMessage.message != null) {
          this.stateObservable.next(this.message());
        }
      }
    )
  }

  public anounce(state) {
    this.log();
    this.currentMessage.message = state;
    this.currentMessage.emmited = moment.now();
    if (state == null) {
      this.stateObservable.next(null);
    }
  }

  public getAppState$(): Observable<any> {
    return this.stateObservable.asObservable();
  }

  private log() {
    if (this.currentMessage.message != null) {
      this.l.log(this.message());
    }
  }

  private message() {
    return this.currentMessage.message + ' (' + this.getDuration() + ')';
  }

  private getDuration() {
    // let duration = this.currentMessage.duration + ' секунди';
    return moment.utc(moment.duration(moment().diff(this.currentMessage.emmited)).asMilliseconds()).format('mm.ss.S');
  }
}
