import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { UserService } from "../_services/user.service";
import { AuthenticationService } from "../Authentication/authentication.service";
import { NotificationService } from '../../notification.service';
import { StateService } from '../../state/state.service';

@Injectable({
    providedIn: 'root'
  })
export class AuthGuard implements CanActivate {

    constructor(
        private _router: Router,
        private _userService: UserService,
        private authenticationService: AuthenticationService,
        private notify: NotificationService,
        private stateService: StateService,
        ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.notify.setLoading(true);
        this.stateService.anounce("Проверявам потребителя");
        return this._userService.verify()
        .then(
            data => {
                this.stateService.anounce(null);
                this.notify.setLoading(false);
                if (data !== null && data.hasOwnProperty('value')) {
                    // logged in so return true
                    return Promise.resolve(true);
                }
                // error when verify so redirect to login page with the return url
                this._router.navigate(['/auth'], { queryParams: { returnUrl: state.url } });
                return Promise.reject(true);
            })
            .catch(error => {
                this.stateService.anounce(null);
                this.notify.setLoading(false);
                // error when verify so redirect to login page with the return url
                this.authenticationService.logout();
                this._router.navigate(['/auth'], { queryParams: { returnUrl: state.url } });
                return Promise.reject(false);
                if (error.status === 403 || error.status === 401) {
                }
                return Promise.reject(false);
            })
            ;
    }
}