import { Injectable } from '@angular/core';
import { HttpClient as Http, HttpHeaders as Headers, HttpResponse as Response } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { Router } from "@angular/router";
import { SettingsService } from "../../settings.service";

@Injectable({
    providedIn: 'root'
  })
export class AuthenticationService {
    public token: string;
    public user: string;
    public settingsService: SettingsService;
    private userLoginLogoutState: any;

    public getUserState$() {
        return this.userLoginLogoutState.asObservable();
    }

    constructor(private router: Router, private http: Http, settingsService: SettingsService) {
        this.userLoginLogoutState = new Subject();
        this.settingsService = settingsService;
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }

    private prepareHeaders(): any{
        let headers = new Headers({ 'Content-Type': 'application/json' }); // ... Set content type to JSON
        let options = { headers: headers };
        return options;
    }

    public login(username: string, password: string) {
        let thisAuthenticationService = this;
        return this.http.post(this.settingsService.serverAddr + 'Login', {
            "app": this.settingsService.appName,
            "user": username,
            "pass": password,
            "lang": this.settingsService.lang
        },
            this.prepareHeaders())
            .toPromise()
            .then((response:any) => {
                let token = response.authorization;
                if (token) {
                    // set token property
                    thisAuthenticationService.token = token;
                    thisAuthenticationService.user = username;

                    // store username and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify({ username: username, token: token }));
                    // return true to indicate successful login
                    this.announceUserIsLogIn(username);
                    return Promise.resolve(thisAuthenticationService.returnResponse(response));
                } else {
                    console.error("Cannot extract token from the response message!");
                    this.announceUserLogOut(username);
                    // return false to indicate failed login
                    return Promise.reject(thisAuthenticationService.returnResponse(response));
                }
            })
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            })
    }


    returnResponse(response) {
        let retVal = {
            body: response,
        };
        return retVal;
    }

    logout() {
        let thisAuthenticationService = this;
        let subject = new Subject();
        //TODO защо става това
        if (this.token == null) {
            var currentUser = JSON.parse(localStorage.getItem('currentUser'));
            this.token = currentUser && currentUser.token;
        }
        // clear token remove user from local storage to log user out
        if (this.token) {
            this.logoutFromServer()
                .then(function(res) {
                    thisAuthenticationService.token = null;
                    localStorage.removeItem('currentUser');
                    thisAuthenticationService.announceUserLogOut();
                    subject.next(true);
                    thisAuthenticationService.router.navigate(['auth']);
                }, function(err) {
                    //FIXME what we will do when on server is not log out
                    thisAuthenticationService.token = null;
                    localStorage.removeItem('currentUser');
                    thisAuthenticationService.announceUserLogOut();
                    subject.error(err);
                    thisAuthenticationService.router.navigate(['auth']);
                });
        }
        else {
            subject.next(true);
            thisAuthenticationService.router.navigate(['']);
        }

        return subject.asObservable();
    }

    logoutFromServer() {
        let headers = new Headers({ 'Authorization': this.token });
        let options = { headers: headers };
        return this.http.post(this.settingsService.serverAddr + 'Logout', {}, options).toPromise().then(function(res) {
            return Promise.resolve(res);
        }, function(err) {
            console.error('User not log out successfully from server side!', err);
            return Promise.reject(err);
        });
    }

    announceUserIsLogIn(username: string) {
        this.userLoginLogoutState.next({ username: username, login: true });
    }
    announceUserLogOut(username?) {
        username = username || null;
        this.userLoginLogoutState.next({ username: username, login: false });
    }
}
