import { Component } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import {FormGroup, FormControl, Validators} from "@angular/forms";
import { GeneralEnterpriseCompanies } from 'src/app/services/service/DataProviders/GeneralEnterpriseCompanies';
import { GeneralContactsCompanyLocations } from 'src/app/services/service/DataProviders/GeneralContactsCompanyLocations';
import { take } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as _ from "lodash";
import { ProductionShopFloorWorkOrders } from 'src/app/services/service/DataProviders/ProductionShopFloorWorkOrders';

@Component({
  selector: 'waste',
  templateUrl: './waste.component.html',
  styleUrls: ['./waste.component.scss'],
})
export class WasteComponent {

  constructor(
    private notify: NotificationService,
    private eCompanyService: GeneralEnterpriseCompanies,
    private eLocationService: GeneralContactsCompanyLocations,
    private woService: ProductionShopFloorWorkOrders,
    private http: HttpClient,
  ) {
  }

  companies = [];
  locations = [];

  // backendUrl = 'http://localhost:8001/';
  backendUrl = 'https://husnew.iaxus.com/';

  async ngOnInit() {
    this.companies = (await this.eCompanyService.query({}).pipe(take(1)).toPromise()).value;
    this.locations = (await this.eLocationService.query({}).pipe(take(1)).toPromise()).value;
  }

  form = new FormGroup({
    eCompanies: new FormControl(null),
    eLocations: new FormControl(null),
    from: new FormControl(null, Validators.required),
    to: new FormControl(null, Validators.required),
    pn: new FormControl(null, Validators.required),
    startAfterPn: new FormControl(1, Validators.required),
    startAfter: new FormControl(1, Validators.required),
});

resetForm() {
    this.form.reset();
}

startProcessing() {

  if(!_.isArray(this.form.value.eCompanies)) {
    this.form.value.eCompanies = [];
  }

  if(!_.isArray(this.form.value.eLocations)) {
    this.form.value.eLocations = [];
  }

  let eCompanyIds = this.form.value.eCompanies.map(i => _.get(i, ['@odata.id']));
  let eLocationIds = this.form.value.eLocations.map(i => _.get(i, ['@odata.id']));

  let params = new HttpParams();

  for (const cId of eCompanyIds) {
    params = params.append('eCompanies[]', cId);
  }

  for (const lId of eLocationIds) {
    params = params.append('eLocations[]', lId);
  }

  params = params.append('from', this.form.value.from);
  params = params.append('to', this.form.value.to);
  params = params.append('delay', this.form.value.startAfter);

  this.callApp('statrtCostCorrectionForProductionBaseOnCompany', params)
}


startProcessingPn() {
  this.notify.setLoading(true);
  let woNumber = this.form.value.pn;
  let state = JSON.parse(JSON.stringify(this.woService.stateTemplate));

  state.filter.filters.push({
    field: 'DocumentNo',
    operator: 'eq',
    value: "'" + woNumber + "'",
  });

  this.woService.filter(state).pipe(take(1)).toPromise()
  .then((res:any) =>  {

    let wo = _.first(res.value);

    let params = {
      wo: wo['Id'],
      delay: this.form.value.startAfterPn,
    };
    this.callApp('startCostCorrectionForProductionOrder', params)

    this.notify.setLoading(false);
  })
  .catch((err) => {
    console.error(err)
    this.notify.setLoading(false);
  });


  // let eCompanyIds = this.form.value.eCompanies.map(i => _.get(i, ['@odata.id']));
  // let eLocationIds = this.form.value.eLocations.map(i => _.get(i, ['@odata.id']));


}

callApp(route, params) {
  this.http.get(this.backendUrl + route, {
    responseType: 'text',
    params
  })
  .toPromise()
  .then(response => {
    alert(response);
    this.resetForm();
  })
  .catch((e) => {
    console.log(e);
    this.resetForm();
  })
}

  n(message) {
    this.notify.notify({
      type: 'info',
      title: 'Добавена задача',
      text: message,
      callback: function (e) {
        console.log(e);
      }
    })
  }
}
