import { TenantConfig } from "./tenant-config";

export const Tenants = {
  AGIVA: new TenantConfig(
    "Agiva Ltd",
    "https://agiva.my.erp.net/api/domain/",
    "https://agivaest.my.erp.net/api/domain/"
  ),
  default: new TenantConfig(
    "Hus Ltd",
    "https://hus-2.my.erp.net/api/domain/",
    "https://hustest.my.erp.net/api/domain/"
  ),

  MANIA: new TenantConfig(
    "Mania Ltd",
    "https://maniastoresbg.my.erp.net/api/domain/",
    "https://maniastoresbg.my.erp.net/api/domain/"
  ),

  ITC: new TenantConfig(
    "ITC Ltd",
    "https://itc-consult-demo.my.erp.net/api/domain/",
    "https://itc-consult-demo.my.erp.net/api/domain/"
  ),
  MARS: new TenantConfig(
    "MarsArmor Ltd",
    "https://e1.marsarmor.com:443/api/domain/",
    "https://e1.marsarmor.com:443/api/domain/"
  ),
  BIOFRESH: new TenantConfig(
    "Biofresh Ltd",
    "https://biofresh.my.erp.net:443/api/domain/",
    "https://biofresh.my.erp.net:443/api/domain/"
  ),
};
