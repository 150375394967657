import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CallServiceAbstract } from "../call.service";
import { SettingsService } from "../../settings.service";
import { AuthenticationService } from "../../auth/Authentication/authentication.service";
import { Subject } from "rxjs";
import { NotificationService } from "../../notification.service";
import { StateService } from "../../state/state.service";

@Injectable({
  providedIn: "root",
})
export class ProductionShopFloorWorkOrders extends CallServiceAbstract {
  constructor(
    http: HttpClient,
    settings: SettingsService,
    authentication: AuthenticationService,
    notify: NotificationService,
    state: StateService
  ) {
    super(
      http,
      settings,
      authentication,
      notify,
      state,
      "Production_ShopFloor_WorkOrders",
      "Производствени Нареждания"
    );
  }

  public predefineState(state) {
    state.expand.push(
      `Items($expand=Recipe(
      $expand=Ingredients($expand=Material($select=Name;$expand=MeasurementUnit($select=Code)),Store($select=Id,Name),UsageUnit($select=Code)),
      Product($select=Name;$expand=MeasurementUnit($select=Code)),
      Store($select=Id,Name)
      ))`
    );

    state.filter.filters.push({
      field: 'Void',
      operator: 'eq',
      value: "false",
    });

    return state;
  }

  filter(filter) {
    let obz = new Subject();
    this.query(filter).subscribe(
      (data) => obz.next(data),
      (err) => obz.error(err)
    );
    return obz.asObservable();
  }
}
