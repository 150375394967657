<div class="clr-footer" *ngIf="showFooter" style="z-index: 1000">
  <div class="clr-row">
    <div class="clr-col" style="justify-content: flex-start">version: 0.9.6</div>
    <div class="clr-col">
      <span>{{ state }}</span>
    </div>
    <div class="clr-col" style="justify-content: flex-end">
      &copy; 2021 iaxus.com
    </div>
  </div>
</div>