import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { take } from 'rxjs/operators';
import { GeneralProductsProductGroups } from 'src/app/services/service/DataProviders/GeneralProductsProductGroups';
import { LogisticsInventoryStores } from 'src/app/services/service/DataProviders/LogisticsInventoryStores';
import * as _ from "lodash";
import { NotificationService } from 'src/app/services/notification.service';
import * as moment from "moment";
import { FixLotCostService } from 'src/app/services/functions/fix-lot-cost-service';
import { StateService } from 'src/app/services/state/state.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export class GroupNode {
  children: GroupNode[];
  name: string;
  show: string;
  obj: any;
  expand: boolean;
  selected: number;
}

export class GroupFlatNode {
  obj: any;
  level: number;
  expandable: boolean;
  selected: number;
  name: string;
  show: string;
}

export class StoreNode {
  children: StoreNode[];
  name: string;
  show: string;
  obj: any;
  expand: boolean;
  selected: number;
}

export class StoreFlatNode {
  obj: any;
  level: number;
  expandable: boolean;
  selected: number;
  name: string;
  show: string;
}

export class Cost {
  product: any;
  cost: number;
  totalCost: number;
  quantity: number;
  processed: boolean;
}

@Component({
  selector: 'sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss'],
})
export class SalesComponent implements OnInit {

  public groups = [];
  public stores = [];

  public selectedGroups = [];

  public from = moment().startOf('month').format('DD.MM.YYYY');
  public to = moment().format('DD.MM.YYYY');
  public days = 30;
  public lots = false;

  @ViewChild(MatSort) sort: MatSort;

  flatNodeMap = new Map<GroupFlatNode, GroupNode>();
  nestedNodeMap = new Map<GroupNode, GroupFlatNode>();
  treeControl: FlatTreeControl<GroupFlatNode>;
  treeFlattener: MatTreeFlattener<GroupNode, GroupFlatNode>;
  dataSource: MatTreeFlatDataSource<GroupNode, GroupFlatNode>;
  checklistSelection = new SelectionModel<GroupFlatNode>(true /* multiple */);

  flatStoreNodeMap = new Map<StoreFlatNode, StoreNode>();
  nestedStoreNodeMap = new Map<StoreNode, StoreFlatNode>();
  storeTreeControl: FlatTreeControl<StoreFlatNode>;
  storeTreeFlattener: MatTreeFlattener<StoreNode, StoreFlatNode>;
  storeDataSource: MatTreeFlatDataSource<StoreNode, StoreFlatNode>;
  storeChecklistSelection = new SelectionModel<StoreFlatNode>(true /* multiple */);

  displayedColumns: string[] = ['processed', 'code', 'name', 'totalcost', 'quantity', 'currentCost', 'cost'];
  costs = new MatTableDataSource([]);

  constructor(private groupService: GeneralProductsProductGroups,
    private storeService: LogisticsInventoryStores,
    private notify: NotificationService,
    public fixCost: FixLotCostService,
    private state: StateService,
    @Inject(DOCUMENT) private document,
  ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<GroupFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.dataSource.data = [];

    this.storeTreeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.storeTreeControl = new FlatTreeControl<GroupFlatNode>(this.getLevel, this.isExpandable);
    this.storeDataSource = new MatTreeFlatDataSource(this.storeTreeControl, this.storeTreeFlattener);
    this.storeDataSource.data = [];
  }

  getLevel = (node: GroupFlatNode | StoreFlatNode) => node.level;
  isExpandable = (node: GroupFlatNode | StoreFlatNode) => node.expandable;
  getChildren = (node: GroupNode | StoreNode): GroupNode[] | StoreNode[] => node.children;
  hasChild = (_: number, _nodeData: GroupFlatNode | StoreFlatNode) => _nodeData.expandable;
  hasNoContent = (_: number, _nodeData: GroupFlatNode | StoreFlatNode) => _nodeData.name === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: GroupNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.name === node.name
      ? existingNode
      : new GroupFlatNode();
    flatNode.name = node.name;
    flatNode.show = node.show;
    flatNode.obj = node.obj;
    flatNode.level = level;
    flatNode.selected = node.selected;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: GroupFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: GroupFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  itemSelectionToggle(node: GroupFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }


  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  leafItemSelectionToggle(node: GroupFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: GroupFlatNode): void {
    let parent: GroupFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: GroupFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: GroupFlatNode): GroupFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }


  /** Whether all the descendants of the node are selected. */
  storeDescendantsAllSelected(node: StoreFlatNode): boolean {
    const descendants = this.storeTreeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.storeChecklistSelection.isSelected(child);
    });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  storeDescendantsPartiallySelected(node: StoreFlatNode): boolean {
    const descendants = this.storeTreeControl.getDescendants(node);
    const result = descendants.some(child => this.storeChecklistSelection.isSelected(child));
    return result && !this.storeDescendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  storeItemSelectionToggle(node: StoreFlatNode): void {
    this.storeChecklistSelection.toggle(node);
    const descendants = this.storeTreeControl.getDescendants(node);
    this.storeChecklistSelection.isSelected(node)
      ? this.storeChecklistSelection.select(...descendants)
      : this.storeChecklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.storeChecklistSelection.isSelected(child));
    this.storeCheckAllParentsSelection(node);
  }


  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  storeLeafItemSelectionToggle(node: StoreFlatNode): void {
    this.storeChecklistSelection.toggle(node);
    this.storeCheckAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  storeCheckAllParentsSelection(node: StoreFlatNode): void {
    let parent: StoreFlatNode | null = this.storeGetParentNode(node);
    while (parent) {
      this.storeCheckRootNodeSelection(parent);
      parent = this.storeGetParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  storeCheckRootNodeSelection(node: StoreFlatNode): void {
    const nodeSelected = this.storeChecklistSelection.isSelected(node);
    const descendants = this.storeTreeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.storeChecklistSelection.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.storeChecklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.storeChecklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  storeGetParentNode(node: StoreFlatNode): StoreFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.storeTreeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.storeTreeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }


  async ngOnInit() {
    let self = this;
    this.state.anounce('Зареждам групи');
    // this.groups = await this.getGroups();
    Promise.all([
      self.getGroups(),
      self.getStores()
    ])
      .then(res => {
        self.groups = res[0];
        this.dataSource.data = self.groups;
        self.stores = res[1];
        this.storeDataSource.data = self.stores;
        this.state.anounce(null);

        this.treeControl.expand(this.treeControl.dataNodes[0]);
        this.storeTreeControl.expand(this.storeTreeControl.dataNodes[0]);

        //select all
        this.storeTreeControl.dataNodes.forEach(store => this.storeLeafItemSelectionToggle(store));
      })
      .catch(e => console.error(e));
  }

  async generate() {
    let selectedGroupIds = this.getIdsOfSelectedGroups();
    let selectedStoresIds = this.getIdsOfSelectedStores();

    this.costs = new MatTableDataSource([]);
    this.costs.data = _.values(await this.fixCost.calcCosts(selectedGroupIds, selectedStoresIds));
    this.costs.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.costs.filter = filterValue.trim().toLowerCase();
  }

  async store() {
    this.notify.setLoading(true);
    this.state.anounce('Ъпдейтвам StandardCostPerLot в ' + this.costs.data.length + ' продукта');
    for (let i = 0; i < this.costs.data.length; i++) {
      let theCost = this.costs.data[i];
      if(theCost.cost.toFixed(3) != 0) {
        await this.fixCost.update(theCost);
        theCost.processed = true;
        const id = this.document.getElementById(theCost.id);
        if (id) {
          setTimeout(() => {
              id.scrollIntoView({block: 'center', behavior: "smooth"});
          }, 500);
        }
      }
    }

    this.notify.setLoading(false);
    this.state.anounce(null);
  }

  getIdsOfSelectedStores() {
    let ids = [];
    let selectedStores = [];

    for (let i = 0; i < this.storeTreeControl.dataNodes.length; i++) {
      let node = this.storeTreeControl.dataNodes[i];
      if (this.storeChecklistSelection.isSelected(node)) {
        selectedStores.push(node);
      }
    }

    for (let i = 0; i < selectedStores.length; i++) {
      let theStore = selectedStores[i];
      if (_.has(theStore, ['obj', '@odata.id'])) {
        const id = _.get(theStore, ['obj', '@odata.id']);
        ids.push(id);
      }
    }

    ids = _.uniq(ids);
    return ids;
  }

  getIdsOfSelectedGroups() {

    let ids = [];
    this.selectedGroups = [];

    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
      let node = this.treeControl.dataNodes[i];
      if (this.checklistSelection.isSelected(node)) {
        this.selectedGroups.push(node);
      }
    }

    for (let i = 0; i < this.selectedGroups.length; i++) {
      let theGroup = this.selectedGroups[i];
      if (_.has(theGroup, ['obj', '@odata.id'])) {
        ids.push(_.get(theGroup, ['obj', '@odata.id']));
      }
    }

    ids = _.uniq(ids);
    return ids;
  }


  async getGroups() {
    let call = JSON.parse(JSON.stringify(this.groupService.stateTemplate));

    call.filter.filters.push({
      field: 'Active',
      operator: 'eq',
      value: 'true',
    });

    let groups = await this.groupService.query(call).pipe(take(1)).toPromise();

    // let filteredGroups = _.filter(groups.value, (g) => {
    //   return (_.includes(_.toLower(g.FullPath), _.toLower('Продукти/'))
    //     || _.includes(_.toLower(g.FullPath), _.toLower('ТЗ Транзитни кодове/'))
    //     || _.includes(_.toLower(g.FullPath), _.toLower('Суровини/'))
    //   )
    // }
    // );

    let filteredGroups = groups.value;
    filteredGroups = _.orderBy(filteredGroups, (g) => g.FullPath);
    return this.prepareGroupsForTree(filteredGroups);
  }

  private prepareGroupsForTree(groups) {
    let retVal = <GroupNode>{
      children: [],
      name: '/',
      code: 'root',
      obj: null,
      expand: true,
      selected: 0,
      show: `/`,
    };
    let maxDepth = 0;

    for (let i = 0; i < groups.length; i++) {
      let g = groups[i];
      let paths = g.FullPath.split('/');
      if (paths.length > maxDepth) {
        maxDepth = paths.length;
      }
      paths.shift(); //махам първият елемент защото започва с наклонена
      paths.pop(); //махам последния защото завършват с наклонена 

      let ref = retVal;
      for (let j = 0; j < paths.length; j++) {
        let p = paths[j];

        // if (!_.has(ref.items, p)) {
        let theItem = _.find(ref.children, c => c.code === p);
        if(j != paths.length - 1) {
          if (!theItem) {
            ref.children.push(<GroupNode>{
              children: [],
              obj: null,
              name: 'no name',
              code: p,
              expand: false,
              selected: 0,
              show: `(${p}) no name`,
            });

            ref = _.find(ref.children, { code: p });
          }
          else {
            ref = theItem;
            if(g.Code === p) {
              ref.obj = theItem;
              ref.name = theItem.Name.BG;
              ref.show = `(${theItem.Code}) ${theItem.Name.BG}`;
            }
          }
        }
        else {
          //добавям най-долна група
          ref['children'].push(<GroupNode>{
            children: [],
            obj: g,
            name: g.Name.BG,
            code: g.Code,
            expand: false,
            selected: 0,
            show: `(${g.Code}) ${g.Name.BG}`
          });
        }
      }
    }
    // console.log('max', maxDepth);
    return [retVal];
  }


  groupName(g) {
    return _.get(g, 'Name.BG');
    return '(' + _.get(g, 'Code') + ') ' + _.get(g, 'Name.BG');
  }

  async getStores() {
    let call = JSON.parse(JSON.stringify(this.storeService.stateTemplate));
    let stores = await this.storeService.query(call).pipe(take(1)).toPromise();
    return this.prepareStoresForTree(stores.value);
  }

  private prepareStoresForTree(stores) {
    let retVal = <StoreNode>{
      children: [],
      name: '/',
      obj: null,
      expand: true,
      selected: 0
    };;
    for (let i = 0; i < stores.length; i++) {
      let s = stores[i];
      let item = <StoreNode>{
        name: _.get(s, 'Name.BG', 'Няма име'),
        selected: 0,
        children: [],
        expand: false,
        obj: s,
      };
      retVal.children.push(item);
    }

    retVal.children = _.orderBy(retVal.children, 'name', 'asc');
    return [retVal];
  }



}
