<div class="content-container">
    <div class="content-area">
      <div class="clr-row">
        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
          <div class="card">
            <div class="card-header">
              По Собствена фирма
            </div>
            <div class="card-block card-height">
              <form clrForm [formGroup]="form">

                <clr-combobox-container>
                  <label>Собствена фирма</label>
                  <clr-combobox clrMulti="true" formControlName="eCompanies">
                    <ng-container *clrOptionSelected="let selected">
                      {{selected.Company.Name.BG}}
                    </ng-container>
                    <clr-options>
                      <clr-option *clrOptionItems="let item of companies" [clrValue]="item">
                        {{item.Company.Name.BG}}
                      </clr-option>
                    </clr-options>
                  </clr-combobox>
                </clr-combobox-container>
                <clr-combobox-container>
                  <label>Локация</label>
                  <clr-combobox clrMulti="true" formControlName="eLocations">
                    <ng-container *clrOptionSelected="let selected">
                      {{selected.PartyName.BG}}
                    </ng-container>
                    <clr-options>
                      <clr-option *clrOptionItems="let item of locations | locationFilter:form.value.eCompanies" [clrValue]="item">
                        {{item.PartyName.BG}}
                      </clr-option>
                    </clr-options>
                  </clr-combobox>
                </clr-combobox-container>
                <clr-date-container>
                    <label>От: </label>
                    <input type="date" clrDate formControlName="from">
                </clr-date-container>
                <clr-date-container>
                  <label>До: </label>
                  <input type="date" clrDate formControlName="to">
                </clr-date-container>
                <clr-input-container>
                  <label>Oтложено стартиране</label>
                  <input clrInput type="number" placeholder="Минути" formControlName="startAfter" />
                </clr-input-container>
          </form>
            </div>
            <div class="card-footer">
              <button class="btn btn-primary" type="button" (click)="startProcessing()">Стартирай Обработка</button>
              <button class="btn" type="button" (click)="resetForm()">Изчисти</button>
            </div>
          </div>
        </div>

        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
          <div class="card">
            <div class="card-header">
              По ПН
            </div>
            <div class="card-block card-height">
              <form clrForm [formGroup]="form">
                <clr-input-container>
                  <label>Номер</label>
                  <input clrInput type="text" placeholder="Номер на ПН" formControlName="pn" />
                </clr-input-container>
                <clr-input-container>
                  <label>Oтложено стартиране</label>
                  <input clrInput type="number" placeholder="Минути" formControlName="startAfterPn" />
                </clr-input-container>
          </form>
            </div>
            <div class="card-footer">
              <button class="btn btn-primary" type="button" (click)="startProcessingPn()">Стартирай Обработка</button>
              <button class="btn" type="button" (click)="resetForm()">Изчисти</button>
            </div>
          </div>
        </div>

    </div>
  </div>
</div>