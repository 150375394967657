import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { GeneralProductsProducts } from '../service/DataProviders/GeneralProductsProducts';
import * as _ from "lodash";
import { StateService } from '../state/state.service';
import { Logistics_Inventory_CurrentBalances } from '../service/DataProviders/Logistics_Inventory_CurrentBalances';
import { NotificationService } from '../notification.service';
import { Logistics_Inventory_StoreTransactionLines } from '../service/DataProviders/Logistics_Inventory_StoreTransactionLines';
import { Cost } from 'src/app/components/sales/sales.component';


@Injectable({
    providedIn: 'root'
})
export class FixLotCostService {

    private cost = {};
    constructor(
        private productsService: GeneralProductsProducts,
        private state: StateService,
        private availabilityService: Logistics_Inventory_CurrentBalances,
        private notify: NotificationService,
        private storeTransactionLines: Logistics_Inventory_StoreTransactionLines,
        private productService: GeneralProductsProducts,
    ) { }

    async update(data) {
        let updateWith = {
            "StandardCostPerLot": {
                "Value": _.round(data.cost, 3)
            }
        };
        let productObj = _.get(data.product, ['@odata.id']);
        let productId = productObj.slice(productObj.indexOf('(') + 1, productObj.indexOf(')'));
        return await this.productService.storeRecord(productId, updateWith).pipe(take(1)).toPromise();
        // return await this.sleep(400);
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async calcCosts(selectedGroupIds, selectedStoresIds) {
        this.cost = {};
        this.notify.setLoading(true);
        // console.log(selectedGroupIds)


        this.state.anounce('Четат се продукти в ' + selectedGroupIds.length + ' групи');
        let products = await this.getProducts(selectedGroupIds);
        // console.log("products", products);
        let productsCount = products.length;
        this.state.anounce(null);


        this.state.anounce('Четат се наличности за ' + productsCount + ' продуктa');
        let availability = await this.getAvailability(products, selectedStoresIds);
        let availabilityRecordsGroupedByProduct = _.groupBy(availability, (l) => _.get(l, ['Product', '@odata.id']));
        let availabilityGroupedByProduct =
            _.mapValues(availabilityRecordsGroupedByProduct
                , av => _.sumBy(av, 'QuantityBase.Value'));
        // console.log('availabilityGroupedByProduct', availabilityGroupedByProduct);

        let costRecordsGroupedByProduct = _.groupBy(availability, (l) => _.get(l, ['Product', '@odata.id']));
        let costGroupedByProduct =
            _.mapValues(costRecordsGroupedByProduct
                , av => _.sumBy(av, 'ProductCost.Value'));
        // console.log('costGroupedByProduct', costGroupedByProduct);

        for (let key in costGroupedByProduct) {
            let p = _.find(products, function (p) { return _.get(p, ['@odata.id']) == key; });

            let totalCost = costGroupedByProduct[key];
            let availableQuantity = availabilityGroupedByProduct[key];
            let theCost = 0;
            if(availableQuantity != 0) {
                theCost = totalCost / availableQuantity
            }

            if (theCost < 0) {
                theCost = 0;
            }

            this.cost[key] = <Cost>{
                cost: theCost,
                totalCost: totalCost,
                quantity: availableQuantity,
                product: p,
                id: p.Id,
                processed: false,
                name: p.Name.BG || '',
                code: p.PartNumber || '',
                currentCost: _.get(p, 'StandardCostPerLot.Value', ''),
            };
        }
        // console.log('cost', this.cost);
        this.state.anounce(null);

        let productsWithoutAvailability = _.filter(products, p => !_.has(this.cost, _.get(p, ['@odata.id'])));
        // console.log('productsWithoutAvailability', productsWithoutAvailability);


        this.state.anounce('Чета редове от СР за ' + productsWithoutAvailability.length + ' продукта');
        for (let i = 0; i < productsWithoutAvailability.length; i++) {
            let p = productsWithoutAvailability[i];
            await this.getCostFromStoreTransactions(p, selectedStoresIds);
        }

        this.state.anounce(null);
        this.notify.setLoading(false);
        // console.log(this.cost);

        return this.cost;
    }

    async getProducts(productGroups) {
        let chunks = _.chunk(productGroups, 50);

        let productsState = JSON.parse(JSON.stringify(this.productsService.stateTemplate));

        productsState.filter.filters.push({
            field: 'Active',
            operator: 'eq',
            value: 'true',
        });

        let results = await Promise.all(chunks.map(async (groupsChunk) => {
            let locState = JSON.parse(JSON.stringify(productsState));
            for (let i = 0; i < groupsChunk.length; i++) {
                locState.filter.filters.push({ field: 'ProductGroup', operator: 'eq', value: "'" + groupsChunk[i] + "'" });
            }
            let products = await this.productsService.query(locState).pipe(take(1)).toPromise();
            return products.value;
        }));

        return _.concat(...results)
    }

    async getAvailability(products, selectedStoresIds) {
        let call = JSON.parse(JSON.stringify(this.availabilityService.stateTemplate));

        // call.filter.filters.push({ field: 'QuantityBaseValue', operator: 'ge', value: "0.001" });
        return this.splitAndGet(products, 'Product', selectedStoresIds, 'Store', call, this.availabilityService);
    }

    async getCostFromStoreTransactions(product, selectedStoresIds) {
        let call = JSON.parse(JSON.stringify(this.storeTransactionLines.stateTemplate));
        let productId = _.get(product, ['@odata.id']);

        // call.filter.filters.push({ field: 'TransactionObj/Store', operator: 'eq', value: selectedStoresIds });
        call.filter.filters.push({ field: 'TransactionObj/IsReleased', operator: 'eq', value: "true" });
        call.filter.filters.push({ field: 'TransactionObj/Void', operator: 'eq', value: "false" });
        call.filter.filters.push({ field: 'TransactionObj/MovementType', operator: 'eq', value: "'Receipt'" });
        call.filter.filters.push({ field: 'TransactionObj/AdjustedDocument', operator: 'eq', value: "null" });
        // call.filter.filters.push({ field: 'Product', operator: 'eq', value: "'" + productId + "'" });

        // let storeTrLines = await this.storeTransactionLines.query(call).pipe(take(1)).toPromise();
        let storeTrLines = await this.splitAndGet([product], 'Product', selectedStoresIds, 'TransactionObj/Store', call, this.storeTransactionLines);
        // console.log('storeTrLines', storeTrLines)

        let cost = _.sumBy(storeTrLines.value, 'LineProductCost.Value')
        let quantity = _.sumBy(storeTrLines.value, 'QuantityBase.Value')

        // console.log('cost', cost)
        // console.log('quantity', quantity)

        let theCost = quantity == 0 ? 0 : cost / quantity;

        if (theCost < 0) {
            theCost = 0;
        }

        this.cost[productId] = <Cost>{
            cost: theCost,
            totalCost: cost,
            quantity: quantity,
            product: product,
            processed: false,
            name: product.Name.BG,
            code: product.PartNumber || '',
        };
    }

    async splitAndGet(products, productField, stores, storeField, state, service) {
        let productC = _.chunk(products, 30);
        let storesC = _.chunk(stores, 15);

        let results = [];
        let prom = [];

        for (let i = 0; i < storesC.length; i++) {
            for (let j = 0; j < productC.length; j++) {
                let locState = JSON.parse(JSON.stringify(state));
                for (let jj = 0; jj < productC[j].length; jj++) {
                    locState.filter.filters.push({ field: productField, operator: 'eq', value: "'" + _.get(productC[j][jj], ['@odata.id']) + "'" });
                }
                for (let ii = 0; ii < storesC[i].length; ii++) {
                    locState.filter.filters.push({ field: storeField, operator: 'eq', value: "'" + storesC[i][ii] + "'" });
                }
                prom.push(service.query(locState).pipe(take(1)).toPromise())
            }
        }
        results = _.concat(results, await Promise.all(prom));
        return _.concat(..._.map(results, (r) => r.value));
    }

}