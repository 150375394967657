import { Component, ViewEncapsulation } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationType } from './notification-type';

@Component({
    selector: "app-notification",
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    // encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent {

    public isModalVisible: boolean = false;

    public notification: NotificationType;

    constructor(private notificationService: NotificationService) {
        this.notificationService.getNotifications$()
            .subscribe((notification) => this.notify(notification));
    }

    private notify(notification) {
        if(!(notification instanceof NotificationType)) {
            notification = new NotificationType(notification);
        }

        this.processNotification(notification)
    }

    yes() {
        if(this.notification.hasCallback()) {
            this.notification.callback();
        }
        this.isModalVisible = false;
    }

    no() {
        if(this.notification.hasNoCallback()) {
            this.notification.no();
        }
        this.isModalVisible = false;
    }

    ok() {
        this.isModalVisible = false;
    }

    processNotification(notification: NotificationType) {
        this.isModalVisible = false;
        this.notification = notification;
        this.isModalVisible = true;
        console.log(notification);
    }
}