import { Pipe, PipeTransform } from '@angular/core';
import * as _ from "lodash";

@Pipe({
  name: 'locationFilter'
})
export class LocationFilterPipe implements PipeTransform {

  transform(items: any, eCompanies: any[]): any {
    if(_.isNull(items) || _.isNull(eCompanies) || items.length == 0 || eCompanies.length == 0) {
      return [];
    }

    let eCompanyIds = eCompanies.map(c => {
      return _.get(c, ['Company', '@odata.id']);
    });

    console.log(eCompanyIds);

    let retVal = items.filter( i => {
      let eCompanyId = _.get(i, ['Company','@odata.id']);
      return eCompanyIds.indexOf(eCompanyId) != -1;
    });

    return retVal;
  }

}
