<div class="login-wrapper">
  <form class="login">
    <label class="title">
      <h3 class="welcome">Добре дошли</h3>
      Стандартна себестойност за партида
      <h5 class="hint">използвайте вашето име и парола за Е1</h5>
    </label>
    <div class="login-group">
      <input class="username" clrInput type="text" name="user" [(ngModel)]="user" autocomplete="off"
        placeholder="потребителско име">
      <input class="password" clrInput type="password" name="password" [(ngModel)]="password" autocomplete="off"
        placeholder="парола">
      <div class="error active" *ngIf="hasError">
        Непознат потребител
      </div>

      <button *ngIf="!loading" type="submit" (click)="login()" class="btn btn-primary">вход</button>
      <div class="btn" *ngIf="loading">
        <clr-spinner clrInline style="margin-right: 10px;">
          Влизане....
        </clr-spinner>
        <span>
          Влизане....
        </span>
      </div>
    </div>
  </form>
</div>