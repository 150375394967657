import { Component, Input } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { StateService } from 'src/app/services/state/state.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Input() showFooter:boolean = false;

  public state:string = null;

  constructor(
    private notify: NotificationService,
    private appState: StateService,
  ) {}

  ngOnInit() {
    this.appState.getAppState$().subscribe(state => this.state = state);
  }
}
