import { Component, Input } from "@angular/core";
import { AuthenticationService } from "src/app/services/auth/Authentication/authentication.service";
import { TenantService } from "src/app/services/tenant/tenant.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
  @Input() showHeader: boolean = false;

  public menu = [
    {
      name: "Стандартна Себестойност",
      link: "standartcost",
    },
    {
      name: "Остойноставане на отпадък",
      link: "waste",
    },
    // {
    //   name: 'Приключване ПН',
    //   link: 'complete'
    // },
  ];

  public tenant = null;

  constructor(private auth: AuthenticationService,
    private tenantService: TenantService,
  ) {
    this.tenant = this.tenantService.getTenant();

    if (this.tenant.name != 'Hus Ltd') {
      this.menu = [
        {
          name: "Стандартна Себестойност",
          link: "standartcost",
        }
      ];
    }
  }

  logout() {
    this.auth.logout();
  }
}
