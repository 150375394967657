import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./components/home/home.component";
import { AuthGuard } from "./services/auth/_guards/auth.guard";
import { LoginComponent } from "./components/app/login/login.component";
import { SalesComponent } from './components/sales/sales.component';
import { WasteComponent } from "./components/waste/waste.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "auth",
    component: LoginComponent,
  },
  {
    path: "standartcost",
    component: SalesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "waste",
    component: WasteComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "**",
    component: HomeComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false, enableTracing: false }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
