import { Injectable } from "@angular/core";
import { HttpClient as Http, HttpHeaders as Headers} from '@angular/common/http';
import { SettingsService } from "../../settings.service";

@Injectable({
    providedIn: 'root'
  })
export class UserService {
    constructor(
        private http: Http,
        private settingsService: SettingsService) {
    }

    verify() {
        return this.http.get(this.settingsService.apiUrl + 'Systems_Core_Config?$top=1', this.token())
            .toPromise()
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    private token() {
        // create authorization header with token token
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.token) {
            let headers = new Headers({ 'Authorization': currentUser.token });
            return {withCredentials:false, headers: headers };
        }
    }
}