import { Injectable } from '@angular/core';
import { NotificationService } from "./notification.service";
import * as moment from "moment-timezone";
import { LogService } from './log/log.service';
import { TenantService } from './tenant/tenant.service';


@Injectable({
    providedIn: 'root'
  })
export class SettingsService {

    public appVersion = null;

    // прод
    public serverAddr: string = null;
    public apiUrl: string = null;

    public lang = null;
    public appName = null;

    //this is for moment lib
    public e1DateFormat = 'YYYY-MM-DD';
    //добави това когато искаш да филтрира по датетиме
    public e1TimeMidnight = 'T00:00:00';
    public timezoneOffset = new Date().getTimezoneOffset();
    public timezone = moment.tz.guess();
    public flagLocalEntitiesInitialised: boolean = false;

    constructor(
        private notification: NotificationService,
        private tenantService: TenantService,
        private l: LogService,
    ) {
        this.initTenantSettings();
        this.initLocalSettings();
    }

    private initTenantSettings() {
        let tenant = this.tenantService.getTenant();
        console.log(this.tenantService.getTenant().name);

        this.serverAddr = tenant.getApi();
        this.apiUrl = this.serverAddr + 'odata/';
        this.lang = tenant.lang;
        this.appName = tenant.appName;
    }

    public propertiesTransverUrl(id) {
        //test
        // let link = "https://husnew.iaxus.com/transferCustomPropertiesValuesInProduction?ID="+id+"&IDLIST="+id+"&SERVER=tcp://husltd.com.my.erp.net:30031&DATABASE=E1_HUS_TEST&ENTITYNAME=Prd_Output_Orders&REPOSITORYNAME=Production.ShopFloor.OutputOrders&DATABASEURL=hustest.my.erp.net";

        //prod
        let link = "https://husnew.iaxus.com/transferCustomPropertiesValuesInProduction?ID="+id+"&IDLIST="+id+"&SERVER=tcp://husltd.com.my.erp.net:30031&DATABASE=E1_HUS&ENTITYNAME=Prd_Output_Orders&REPOSITORYNAME=Production.ShopFloor.OutputOrders&DATABASEURL=husltdcom.my.erp.net";
        return link;
    }

    public propertiesTransverUrlInProduction(id) {
        //prod
        let link = "https://husnew.iaxus.com/transferCustomPropertiesValuesInProduction?ID="+id+"&IDLIST="+id+"&SERVER=tcp://husltd.com.my.erp.net:30031&DATABASE=E1_HUS&ENTITYNAME=Prd_Work_Orders&REPOSITORYNAME=Logistics.Inventory.StoreTransactions&DATABASEURL=husltdcom.my.erp.net";
        return link;
    }
    public toOdataStr(state: any) {
        let retVal = '';
        let expand = '';
        let select = '';
        let orderby = '';
        //retVal = retVal.split('(').join('');
        //retVal = retVal.split(')').join('');

        let skip = '';
        let top = '';
        let filter = '';
        let filterArr = [];

        //обработка filter
        if (state.hasOwnProperty('filter') && state.filter.hasOwnProperty('filters') && state.filter.filters.length > 0) {
            state.filter.filters.forEach((f) => {
                f.value = encodeURI(f.value);
                if (f.operator === 'eq' || f.operator === 'ge' || f.operator === 'le') {
                    filterArr.push(f.field + ' ' + f.operator + ' ' + f.value);
                }
                else if (
                    f.operator === 'substringof' 
                    || f.operator === 'endswith' 
                    || f.operator === 'startswith'
                    || f.operator === 'contains'
                    ) {
                    filterArr.push(f.operator + '(' + f.field + ',\'' + f.value + '\')');
                }
                else if (
                    f.operator === 'lessequalnull'
                    || f.operator === 'greaterequalnull'
                    ) {
                    filterArr.push(f.operator + '(' + f.field + ',' + f.value + ')');
                }
                else {
                    this.l.error('not supported filter', f);
                }
            });
            filter = '$filter=' + filterArr.join(' and ');
        }
        if (retVal.length > 0) {
            if (filter.length > 0) {
                retVal += '&' + filter;
            }
        }
        else {
            if (filter.length > 0) {
                retVal += filter;
            }
        }

        //обработка skip
        if (state.hasOwnProperty('skip') && state.skip) {
            skip = '$skip=' + state.skip;
        }
        if (retVal.length > 0) {
            if (skip.length > 0) {
                retVal += '&' + skip;
            }
        }
        else {
            if (skip.length > 0) {
                retVal += skip;
            }
        }

        //обработка top
        if (state.hasOwnProperty('take') && state.take) {
            top = '$top=' + state.take;
        }
        if (retVal.length > 0) {
            if (top.length > 0) {
                retVal += '&' + top;
            }
        }
        else {
            if (top.length > 0) {
                retVal += top;
            }
        }

        //обработка count
        if (retVal.length > 0) {
            // retVal += '&$inlinecount=allpages';
        }
        else {
            // retVal += '$inlinecount=allpages';
        }

        //обработа expand
        if (state.hasOwnProperty('expand') && state.expand.length > 0) {
            expand = '$expand=' + state.expand.join(',');
        }
        if (retVal.length > 0) {
            if (expand.length > 0) {
                retVal += '&' + expand;
            }
        }
        else {
            if (expand.length > 0) {
                retVal += expand;
            }
        }

        //обработка select
        if (state.hasOwnProperty('select') && state.select.length > 0) {
            select = '$select=' + state.select.join(',');
        }
        if (retVal.length > 0) {
            if (select.length > 0) {
                retVal += '&' + select;
            }
        }
        else {
            if (select.length > 0) {
                retVal += select;
            }
        }
        //обработка orderby
        if (state.hasOwnProperty('orderby') && state.orderby.hasOwnProperty('field') && state.orderby.hasOwnProperty('order')) {
            orderby = '$orderby=' + state.orderby.field + ' ' + state.orderby.order;
        }
        if (retVal.length > 0) {
            if (orderby.length > 0) {
                retVal += '&' + orderby;
            }
        }
        else {
            if (orderby.length > 0) {
                retVal += orderby;
            }
        }

        return retVal;
    }


    private localStorageName = 'clientSettings';

    public notify(notification) {
        this.notification.notify(notification);
    }

    private initLocalSettings() {
        let settings = this.getSettingsCurrentState();
        if (!settings) {
            this.setSettings({});
        }
    }

    private save(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }
    private read(key, defaultValue = null) {
        return JSON.parse(localStorage.getItem(key)) || defaultValue;
    }

    private getSettingsCurrentState() {
        return JSON.parse(localStorage.getItem(this.localStorageName));
    }
    private setSettings(settings: Object) {
        localStorage.setItem(this.localStorageName, JSON.stringify(settings));
    }

    public getUser() {
        return this.read('currentUser', null);
    }

    public setUser(user) {
        return this.save('currentUser', user);
    }

    //за всеки сеттинг имам гетер и сетер

    public setEnterpriseComapany(enterpriseCompany) {
        this.save('enterpriseCompany', enterpriseCompany);
    }
    public getEnterpriseCompany() {
        return this.read('enterpriseCompany', null);
    }

    public setEnterpriseCompanies(enterpriseCompanies) {
        this.save('enterpriseCompanies', enterpriseCompanies);
    }
    public getEnterpriseCompanies() {
        return this.read('enterpriseCompanies', []);
    }

    public setEnterpriseOneUserSettings(setiings) {
        this.save('enterpriseOneUserSettings', setiings);
    }
    public getEnterpriseOneUserSettings() {
        return this.read('enterpriseOneUserSettings', null);
    }

    public setEnterpriseCompanyLocation(enterpriseCompanyLocation) {
        this.save('enterpriseCompanyLocation', enterpriseCompanyLocation);
    }
    public getEnterpriseCompanyLocation() {
        return this.read('enterpriseCompanyLocation', null);
    }

    public setUserRoles(roles) {
        this.save('userRoles', roles);
    }
    public getUserRoles() {
        return this.read('userRoles', []);
    }

    public setEnterpriseCompaniesLocations(enterpriseCompaniesLocations) {
        this.save('enterpriseCompaniesLocations', enterpriseCompaniesLocations);
    }
    public getEnterpriseCompaniesLocations() {
        return this.read('enterpriseCompaniesLocations', []);
    }

    public setProductionCustomProperties(productionCustomProperties) {
        this.save('productionCustomProperties', productionCustomProperties);
    }
    public getProductionCustomProperties() {
        return this.read('productionCustomProperties', []);
    }

    public setCustomPropertiesAllowedValues(customProperiesValues) {
        this.save('customPropertiesAllowedValues', customProperiesValues);
    }

    public getCustomPropertiesAllowedValues(filterByProppertyCode) {
        let allValues = this.read('customPropertiesAllowedValues', []);
        let retVal = [];
        allValues.forEach((allowedValue) => {
            if(allowedValue.hasOwnProperty('Property')
            && allowedValue.Property.hasOwnProperty('Code')
            && allowedValue.Property.Code == filterByProppertyCode
            ) {
                retVal.push(allowedValue);
            }
        })

        return retVal;
    }

    public setUsers(users) {
        this.save('users', users);
    }
    public getUsers() {
        return this.read('users', []);
    }

}
