export class TenantConfig {

  public name: string;
  public apiProdUrl: string;
  public apiTestUrl: string;
  public appName: string;
  public lang: string;

  constructor(
    name: string,
    apiProdUrl: string,
    apiTestUrl: string,
    appName: string = 'StandartCostPerLot',
    lang: string = 'bg',
  ) {
    this.name = name;
    this.apiProdUrl = apiProdUrl;
    this.apiTestUrl = apiTestUrl;
    this.appName = appName;
    this.lang = lang;
  }

  public getApi(): string {
    // return this.apiTestUrl;
    return this.apiProdUrl;
  }
}