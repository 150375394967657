import { Component } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  constructor(
    private notify: NotificationService,
  ) {

  }

  n() {
    this.notify.notify({
      type: 'error',
      title: 'E1 Error',
      text: 'erro mesage',
      callback: function (e) {
        console.log(e);
      }
    })
  }
}
