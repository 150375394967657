import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor() { }

  public log(...arg: any[]) {
    console.log(...arg)
    for (let i = 0; i < arg.length; i++) {
      // console.log(arg[i])
    }
  }

  public error(...arg: any[]) {
    console.error(...arg)
    for (let i = 0; i < arg.length; i++) {
      // console.error(arg[i])
    }
  }

  public (...arg: any[]) {
    console.warn(...arg)
    for (let i = 0; i < arg.length; i++) {
      // console.warn(arg[i])
    }
  }
}
