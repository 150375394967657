<header class="header-3" *ngIf="showHeader">
  <div class="branding">
    <a class="nav-link" [routerLink]="''">
      <clr-icon shape="home" size="24"></clr-icon>
      <span class="title">{{ tenant?.name }}</span>
    </a>
  </div>
  <div class="header-nav">
    <a *ngFor="let m of menu" [routerLink]="[m.link]" routerLinkActive="active" class="nav-link nav-text">{{m.name}}</a>
  </div>
  <div class="header-actions">
    <a href="javascript://" class="nav-link nav-icon" aria-label="Изход" (click)="logout()">
      <clr-icon shape="logout"></clr-icon>
    </a>
  </div>
</header>