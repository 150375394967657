<div class="content-container" style="height: 890px;">
  <div class="content-area">
    <!-- <div class="clr-row">
      <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
        <div class="card">
          <div class="card-block">
            <div style="display: flex;">
              <div style="display: flex; justify-content: space-evenly; width: 50%; align-items: center;">
                <clr-date-container>
                  <label>От дата</label>
                  <input type="date" clrDate name="from" [(ngModel)]="from">
                </clr-date-container>
                <clr-date-container>
                  <label>До дата</label>
                  <input type="date" clrDate name="to" [(ngModel)]="to">
                </clr-date-container>
                <clr-input-container>
                  <label>Брой дни</label>
                  <input clrInput name="days" [(ngModel)]="days" />
                </clr-input-container>
                <clr-checkbox-container clrInline>
                  <label>Партиди</label>
                  <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox name="lots" value="true" [(ngModel)]="lots" />
                    <label>Да</label>
                  </clr-checkbox-wrapper>
                </clr-checkbox-container>
              </div>
              <div style="display: flex; justify-content: flex-end; width: 50%; align-items: center;">
                <button class="btn btn-outline" (click)="generate()">Генерирай</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="clr-row">
      <div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
        <div class="card">
          <div class="card-header">
            Продуктови групи
          </div>
          <div class="card-block card-height">

            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
              <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
                <button mat-icon-button disabled></button>
                <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
                  (change)="leafItemSelectionToggle(node)">{{node.name}}</mat-checkbox>
              </mat-tree-node>

              <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                  </mat-icon>
                </button>
                <mat-checkbox [checked]="descendantsAllSelected(node)"
                  [indeterminate]="descendantsPartiallySelected(node)" (change)="itemSelectionToggle(node)">
                  {{node?.show}}</mat-checkbox>
              </mat-tree-node>
            </mat-tree>

          </div>
          <!-- <div class="card-footer"> -->
            <!-- <button class="btn btn-outline" (click)="generate()">Пресметни</button> -->
          <!-- </div> -->
        </div>
      </div>

      <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
        <div class="card">
          <div class="card-header">
            Складове
          </div>
          <div class="card-block card-height">

            <mat-tree [dataSource]="storeDataSource" [treeControl]="storeTreeControl">
              <mat-tree-node *matTreeNodeDef="let store" matTreeNodeToggle matTreeNodePadding>
                <button mat-icon-button disabled></button>
                <mat-checkbox class="checklist-leaf-node" [checked]="storeChecklistSelection.isSelected(store)"
                  (change)="storeLeafItemSelectionToggle(store)">{{store.name}}</mat-checkbox>
              </mat-tree-node>

              <mat-tree-node *matTreeNodeDef="let store; when: hasChild" matTreeNodePadding>
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + store.name">
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{storeTreeControl.isExpanded(store) ? 'expand_more' : 'chevron_right'}}
                  </mat-icon>
                </button>
                <mat-checkbox [checked]="storeDescendantsAllSelected(store)"
                  [indeterminate]="storeDescendantsPartiallySelected(store)" (change)="storeItemSelectionToggle(store)">
                  {{store.name}}</mat-checkbox>
              </mat-tree-node>
            </mat-tree>

          </div>
          <div class="card-footer">
            <button class="btn btn-outline" (click)="generate()">Пресметни</button>
          </div>
        </div>
      </div>

      <div class="clr-col-lg-5 clr-col-md-5 clr-col-12">

        <div class="card">
          <div class="card-header">
            Себестойност
          </div>
          <div class="card-block card-height">

            <div class="filter">
              <input matInput (keyup)="applyFilter($event)" placeholder="филтър">
            </div>

            <table mat-table [dataSource]="costs" matSort class="mat-elevation-z1" style="width: 100%;">

              <ng-container matColumnDef="processed">
                <th style="width: 5%;" mat-header-cell *matHeaderCellDef> # </th>
                <td style="text-align: right; padding-left: 2px !important;" mat-cell *matCellDef="let element">
                  <mat-icon style="transition:1.5s;" [ngClass]="{'processed-icon': element.processed}">done</mat-icon>
                </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th style="width: 60%;" mat-header-cell *matHeaderCellDef mat-sort-header> Продукт </th>
                <td style="text-align: left;" mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>

              <ng-container matColumnDef="code">
                <th style="width: 15%;" mat-header-cell *matHeaderCellDef mat-sort-header> Код </th>
                <td style="text-align: left;" mat-cell *matCellDef="let element"> {{element.code}} </td>
              </ng-container>

              <ng-container matColumnDef="totalcost">
                <th style="width:10%;" mat-header-cell *matHeaderCellDef mat-sort-header> Обща Себестойност </th>
                <td style="text-align: right;" mat-cell *matCellDef="let element"> {{element.totalCost.toFixed(3)}}
                </td>
              </ng-container>

              <ng-container matColumnDef="quantity">
                <th style="width:10%;" mat-header-cell *matHeaderCellDef mat-sort-header> Общо Количество </th>
                <td style="text-align: right;" mat-cell *matCellDef="let element"> {{element.quantity.toFixed(3)}} </td>
              </ng-container>

              <ng-container matColumnDef="currentCost">
                <th style="width:10%;" mat-header-cell *matHeaderCellDef mat-sort-header> Текуща Себестойност </th>
                <td style="text-align: right;" mat-cell *matCellDef="let element"> {{element.currentCost}} </td>
              </ng-container>

              <ng-container matColumnDef="cost">
                <th style="width:10%;" mat-header-cell *matHeaderCellDef mat-sort-header> Ед. Себестойност </th>
                <td style="text-align: right;" mat-cell *matCellDef="let element"> {{element.cost.toFixed(3)}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
              <tr mat-row [id]="row.id" [ngClass]="{'processed': row.processed == true}"
                *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <!-- <mat-tree [dataSource]="storeDataSource" [treeControl]="storeTreeControl">
              <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
                <button mat-icon-button disabled></button>
                <mat-checkbox class="checklist-leaf-node" [checked]="storeChecklistSelection.isSelected(node)"
                  (change)="storeLeafItemSelectionToggle(node)">{{node.name}}</mat-checkbox>
              </mat-tree-node>

              <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{storeTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                  </mat-icon>
                </button>
                <mat-checkbox [checked]="storeDescendantsAllSelected(node)"
                  [indeterminate]="storeDescendantsPartiallySelected(node)" (change)="storeItemSelectionToggle(node)">
                  {{node.name}}</mat-checkbox>
              </mat-tree-node>
            </mat-tree> -->

          </div>
          <div class="card-footer">
            <button class="btn btn-outline" (click)="store()">Запис в Е1</button>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>