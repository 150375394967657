import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import locateBg from "@angular/common/locales/bg";

registerLocaleData(locateBg);

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ClarityModule } from "@clr/angular";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HomeComponent } from "./components/home/home.component";
import { LoginComponent } from "./components/app/login/login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { CompleteComponent } from "./components/complete/complete.component";
import { HeaderComponent } from "./components/app/header/header.component";
import { LoadingComponent } from "./components/app/loading/loading.component";
import { FooterComponent } from "./components/app/footer/footer.component";
import { NotificationComponent } from "./components/app/notification/notification.component";
import { registerLocaleData } from "@angular/common";
import { ProgressComponent } from "./components/app/progress/progress.component";
import { SalesComponent } from './components/sales/sales.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from "@angular/material/sort";
import { WasteComponent } from "./components/waste/waste.component";
import { LocationFilterPipe } from "./location-filter.pipe";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    CompleteComponent,
    HeaderComponent,
    LoadingComponent,
    FooterComponent,
    NotificationComponent,
    ProgressComponent,

    LocationFilterPipe,
    WasteComponent,
    SalesComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    ClarityModule,
    MatTreeModule,
    MatCheckboxModule,
    MatIconModule,
    CdkTreeModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
  ],
  providers: [{ provide: LOCALE_ID, useValue: "bg" }],
  bootstrap: [AppComponent],
})
export class AppModule { }
