import { Injectable } from "@angular/core";
import * as moment from "moment";
import { LogService } from "../log/log.service";
import { TenantConfig } from "./tenant-config";
import { Tenants } from "./tenants";

@Injectable({
  providedIn: "root",
})
export class TenantService {
  private getTenantForHostname(hostname: string): TenantConfig {
    return this.getTenantForHost(hostname.split(".")[0]);
  }

  private getTenantForString(s: string) {
    for (const e in Tenants) {
      if (e.toLowerCase() === s.toLowerCase()) {
        return Tenants[e] as TenantConfig;
      }
    }
    return null;
  }

  private getTenantForHost(host: string): TenantConfig {
    return this.getTenantForString(host);
  }

  getTenant(): TenantConfig {
    let tenant: TenantConfig = this.getTenantForHostname(location.hostname);

    if (tenant == null) {
      tenant = Tenants["default"];
    }

    return tenant;
  }
}
