import { Component } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  
  public user:string = null;
  public userLogged: boolean = false;

  constructor(private _router: Router) {

    this._router.events.subscribe((event: Event) => {
      this.enableDisableHeader(event)
    });

    var currentUser = localStorage.getItem('currentUser');

    if(currentUser != null) {
      this.user = JSON.parse(currentUser).username;
      this.userLogged = true;
      this._router.navigate(['/']);
    }
  }

  private enableDisableHeader(event: Event) {
    if (event instanceof NavigationEnd) {
      if(event.url.indexOf('auth') == -1) {
        this.userLogged = true;
      }
      else {
        this.userLogged = false;
      }
    }
  }
}
