<clr-modal [(clrModalOpen)]="isModalVisible" [clrModalStaticBackdrop]="true" [clrModalClosable]="false" [clrModalSize]="notification?.size">
    <div class="modal-title">{{ notification?.title }}</div>
    <div class="modal-body">
        {{ notification?.text }}
    </div>
    <div class="modal-footer">
        <button *ngIf="!notification?.hasCallback()" type="button" (click)="ok()" class="btn btn-primary">Добре</button>

        <button *ngIf="notification?.hasCallback()" type="button" (click)="yes()" class="btn btn-primary">Не</button>
        <button *ngIf="notification?.hasCallback()" type="button" (click)="no()" class="btn btn-primary">Да</button>
    </div>
</clr-modal>