import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { LogService } from './log/log.service';

@Injectable({
    providedIn: 'root'
  })
export class NotificationService {

    private notificationObservable: any;
    private loadingObservable: any;

    constructor(private l: LogService) {
        this.notificationObservable = new Subject();
        this.loadingObservable = new Subject();
    }

    public notify(notification) {
        this.notificationObservable.next(notification);
    }

    public getNotifications$() {
        return this.notificationObservable.asObservable();
    }

    public getLoading$() {
        return this.loadingObservable.asObservable();
    }

    public setLoading(val) {
        this.loadingObservable.next(val);
    } 

    public badError(err) {
        this.l.error(err);
        this.notify({
            type: 'error',
            title: 'Грешка. Обърнете се към администратор!',
        });
    }

}