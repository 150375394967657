import * as _ from "lodash"

export class NotificationType {

    public title: string;
    public type: string;
    public text: string;
    public callback: Function;
    public no: Function;

    public size: string = 'sm';

    constructor(obj?) {
        if(obj) {
            this.text = obj.text;
            this.title = obj.title;
            this.type = obj.type;
            this.callback = _.has(obj, 'callback') ? obj.callback : null;
            this.no = _.has(obj, 'no') ? obj.no : null;

            if(this.text.length > 100) {
                this.size = 'xl';
            }
        }
    }

    hasCallback() {
        if (typeof this.callback === "function") { 
            return true;
        }

        return false;
    }

    hasNoCallback() {
        if (typeof this.no === "function") { 
            return true;
        }

        return false;
    }
}