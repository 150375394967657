import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CallServiceAbstract } from "../call.service";
import { SettingsService } from "../../settings.service";
import { AuthenticationService } from "../../auth/Authentication/authentication.service";
import { Subject } from 'rxjs';
import { NotificationService } from '../../notification.service';
import { StateService } from '../../state/state.service';

@Injectable({
    providedIn: 'root'
})

export class GeneralProductsProductGroups extends CallServiceAbstract {
    constructor(
        http: HttpClient,
        settings: SettingsService,
        authentication: AuthenticationService,
        notify: NotificationService,
        state: StateService,
    ) {
        super(http, settings, authentication, notify, state, 'General_Products_ProductGroups', 'Групи');
    }

    public predefineState(state) {
        state = super.predefineState(state);
        state.select = [
            'Name',
            // 'Parent',
            'FullPath',
            'Code',
        ];
        state.take = 9999999;
        // state.take = 100;
        return state;
    }

    filter(filter) {
        let obz = new Subject();
        this.query(filter).subscribe(
            data => obz.next(data),
            err => obz.error(err)
        );
        return obz.asObservable();
    }
}