import { Component } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {

  public loading: boolean = false;

  constructor(
    private notify: NotificationService,
  ) {  }

  ngOnInit() {
    this.notify.getLoading$()
    .subscribe(data => this.loading = data);
  }
}
